<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { FTXSlide } from '@/shared/types/ftx';
import { computed } from 'vue';

interface Props {
  slide: FTXSlide;
}

const props = defineProps<Props>();

const { t } = useI18n();

const hasImageSlide = computed<boolean>(
  () => props.slide?.imageSlide !== undefined,
);
</script>

<template>
  <component :is="slide.imageSlide" v-if="hasImageSlide" />
  <div class="ftx-slide__title">
    <p v-if="!hasImageSlide" class="ftx-slide__get-started">
      {{ t('ftx.getStarted') }}
    </p>
    <p>{{ slide.title }}</p>
  </div>
  <p class="ftx-slide__description">{{ slide.description }}</p>
</template>

<style scoped lang="scss">
.ftx-slide__title {
  font-size: 24px;
  color: $gray-800;
  line-height: 32px;
}

.ftx-slide__get-started {
  font-size: 12px;
  color: $gray-400;
  line-height: 18px;
}

.ftx-slide__description {
  font-size: 18px;
  color: $gray-600;
  line-height: 32px;
}
</style>
