import { ApiResponse, ResponseMetadata } from '@/shared/types/generic';
import {
  AudienceType,
  PublicDisplay,
  PublicDisplaysResponse,
} from '@/shared/types/publicDisplays';

const publicDisplaysList = [
  {
    name: 'pd1',
    description: 'desc-pd1',
    displayCode: '001',
    language: 'en-GB',
    activePin: true,
    layoutType: 'all_challenges',
    pin: '123456',
    audienceType: AudienceType.TEAM,
    audiences: ['team1'],
  },
  {
    name: 'um public display com um nome muitoooooooooooo grandeeeeeee e ainda maior que istooooooo',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eget magna lacus. Aliquam vitae lacus odio. Praesent ve.',
    displayCode: '001',
    language: 'en-GB',
    activePin: false,
    layoutType: 'all_challenges',
    pin: '654321',
    audienceType: AudienceType.INDIVIDUAL,
    audiences: ['user1'],
  },
] as PublicDisplay[];

const metadata: ResponseMetadata = {
  totalItems: 2,
  lastUpdated: new Date('2022-11-03T15:12:15.127247').getTime(),
};

export function mockGetPublicDisplays(): ApiResponse<PublicDisplaysResponse> {
  return {
    metadata,
    results: {
      link: 'generic.link',
      items: publicDisplaysList,
    },
  };
}

export const createPublicDisplay = () => {
  publicDisplaysList.push();
  return {
    language: 'PT_pt',
    results: publicDisplaysList,
  };
};
