import { CategorizedMetrics } from '@/shared/types/metrics';
import { getMockCategorizedMetric } from './mockMetrics';

const getCategorizedMetrics = (): CategorizedMetrics => ({
  categories: [
    {
      category: {
        reference: '1',
        name: 'PICKING',
        categoryType: {
          name: 'metric.individual',
          reference: 'ref-mtric-individual',
        },
        metrics: Array(6)
          .fill(0)
          .map(() => getMockCategorizedMetric()),
      },
    },
    {
      category: {
        reference: '2',
        name: 'RECEIVING',
        categoryType: {
          name: 'metric.individual',
          reference: 'ref-mtric-individual',
        },
        metrics: Array(3)
          .fill(0)
          .map(() => getMockCategorizedMetric()),
      },
    },
  ],
  uncategorizedMetrics: Array(2)
    .fill(0)
    .map(() => getMockCategorizedMetric()),
});

export default getCategorizedMetrics;
