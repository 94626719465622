<script setup lang="ts">
import { v4 } from 'uuid';

const uuid = v4();
</script>

<template>
  <svg
    width="361"
    height="151"
    viewBox="0 0 361 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="360"
      height="150"
      transform="translate(0.226562 0.196289)"
      fill="white"
    />
    <path
      d="M124.918 83.6011C123.511 83.6011 122.104 83.0451 120.696 82.2112C118.444 80.5435 117.037 77.7638 117.6 74.9842L119.289 64.1436L111.689 56.3606C109.719 54.4149 108.874 51.3573 110 48.5776C110.845 45.798 113.097 44.1302 115.911 43.5743L126.607 41.9065L131.392 32.1778C132.518 29.6761 135.051 28.0083 137.866 28.0083C140.681 28.0083 143.214 29.6761 144.621 32.1778L149.125 41.9065L159.539 43.5743C162.354 43.8522 164.606 45.798 165.45 48.5776C166.295 51.3573 165.732 54.1369 163.761 56.3606L156.162 64.1436L157.85 74.9842C158.413 77.7638 157.287 80.8214 154.754 82.2112C152.502 83.879 149.688 83.879 147.155 82.7672L137.866 77.4858L128.577 82.4892C127.452 83.3231 126.326 83.6011 124.918 83.6011ZM137.866 33.5676C137.584 33.5676 136.74 33.5676 136.459 34.6794L133.785 40.2387L131.111 45.798C130.548 46.6319 129.703 47.1878 128.859 47.1878L116.756 49.1335C115.911 49.1335 115.63 49.9674 115.348 50.2454C115.348 50.5234 115.067 51.3573 115.911 52.1911L124.637 60.808C125.2 61.364 125.481 62.1978 125.481 63.3097L123.511 75.5401C123.229 76.652 124.074 77.2079 124.355 77.4858C124.637 77.7638 125.2 78.0418 126.044 77.4858L136.74 71.6486C137.585 71.0927 138.71 71.0927 139.555 71.6486L150.251 77.4858C151.095 77.7638 151.658 77.4858 151.94 77.4858C152.221 77.2079 152.784 76.652 152.784 75.5401L150.814 63.3097C150.532 62.4758 150.814 61.6419 151.658 60.808L160.384 52.1911C160.665 51.6352 160.384 50.5234 160.384 50.2454C160.384 49.9674 159.821 49.1335 158.976 49.1335L146.873 47.4658C146.029 47.4658 145.184 46.6319 144.621 45.798L139.273 34.6794C138.992 33.5676 138.147 33.5676 137.866 33.5676Z"
      fill="#F6F6F6"
    />
    <path
      d="M231.115 122.385C230.411 122.385 229.708 122.107 229.004 121.69C227.878 120.856 227.175 119.466 227.456 118.076L228.301 112.656L224.501 108.765C223.516 107.792 223.094 106.263 223.656 104.874C224.079 103.484 225.204 102.65 226.612 102.372L231.96 101.538L234.352 96.674C234.915 95.4232 236.181 94.5894 237.589 94.5894C238.996 94.5894 240.263 95.4232 240.966 96.674L243.218 101.538L248.425 102.372C249.832 102.511 250.958 103.484 251.38 104.874C251.803 106.263 251.521 107.653 250.536 108.765L246.736 112.656L247.581 118.076C247.862 119.466 247.299 120.995 246.033 121.69C244.907 122.524 243.499 122.524 242.233 121.968L237.589 119.327L232.945 121.829C232.382 122.246 231.819 122.385 231.115 122.385ZM237.589 97.3689C237.448 97.3689 237.026 97.3689 236.885 97.9248L234.211 103.484C233.93 103.901 233.508 104.179 233.085 104.179L227.034 105.152C226.612 105.152 226.471 105.569 226.33 105.708C226.33 105.846 226.19 106.263 226.612 106.68L230.974 110.989C231.256 111.267 231.397 111.684 231.397 112.239L230.411 118.354C230.271 118.91 230.693 119.188 230.834 119.327C230.974 119.466 231.256 119.605 231.678 119.327L237.026 116.409C237.448 116.131 238.011 116.131 238.433 116.409L243.781 119.327C244.203 119.466 244.485 119.327 244.625 119.327C244.766 119.188 245.047 118.91 245.047 118.354L244.062 112.239C243.922 111.823 244.062 111.406 244.485 110.989L248.847 106.68C248.988 106.402 248.847 105.846 248.847 105.708C248.847 105.569 248.566 105.152 248.144 105.152L242.092 104.318C241.67 104.318 241.248 103.901 240.966 103.484L238.292 97.9248C238.152 97.3689 237.729 97.3689 237.589 97.3689Z"
      fill="#F6F6F6"
    />
    <g :filter="`url(#${uuid}_filter0_d_1484_21047)`">
      <circle
        cx="180.008"
        cy="86.3257"
        r="31"
        :fill="`url(#${uuid}_paint0_linear_1484_21047)`"
      />
      <g :filter="`url(#${uuid}filter1_i_1484_21047)`">
        <circle
          cx="180.008"
          cy="86.3257"
          r="23.6055"
          :fill="`url(#${uuid}_paint1_radial_1484_21047)`"
        />
      </g>
      <g :filter="`url(#${uuid}_filter2_d_1484_21047)`">
        <path
          d="M177.379 88.03L171.718 78.2516C170.85 76.7399 168.92 76.2252 167.408 77.0937C165.896 77.9621 165.381 79.8921 166.25 81.4039L171.911 91.1822L177.572 100.961C178.151 101.99 179.213 102.537 180.306 102.537C180.853 102.537 181.4 102.408 181.882 102.119C183.394 101.25 183.909 99.3201 183.04 97.8083L177.379 88.03Z"
          :fill="`url(#${uuid}_paint2_linear_1484_21047)`"
        />
        <path
          d="M192.274 76.7362C192.049 76.704 191.824 76.6719 191.599 76.6719H180.308C178.572 76.6719 177.156 78.0872 177.156 79.8241C177.156 81.561 178.572 82.9763 180.308 82.9763H186.13L183.203 88.0263C181.981 90.0849 183.557 92.819 185.937 92.7547C187.031 92.7547 188.093 92.1757 188.672 91.1786L194.333 81.4002C194.333 81.4002 194.429 81.2072 194.494 81.0786C195.298 79.3095 194.172 77.09 192.274 76.704V76.7362Z"
          :fill="`url(#${uuid}_paint3_linear_1484_21047)`"
        />
      </g>
    </g>
    <g :filter="`url(#${uuid}_filter3_d_1484_21047)`">
      <circle
        cx="237.487"
        cy="49.0735"
        r="20.6941"
        :fill="`url(#${uuid}_paint4_linear_1484_21047)`"
      />
      <g :filter="`url(#${uuid}_filter4_i_1484_21047)`">
        <circle
          cx="237.486"
          cy="49.0733"
          r="15.7579"
          :fill="`url(#${uuid}_paint5_radial_1484_21047)`"
        />
      </g>
      <g :filter="`url(#${uuid}_filter5_d_1484_21047)`">
        <path
          d="M235.732 50.211L231.953 43.6835C231.374 42.6743 230.085 42.3307 229.076 42.9105C228.067 43.4902 227.723 44.7786 228.303 45.7878L232.082 52.3153L235.861 58.8428C236.248 59.53 236.956 59.895 237.686 59.895C238.051 59.895 238.416 59.8091 238.739 59.6158C239.748 59.0361 240.091 57.7478 239.512 56.7386L235.732 50.211Z"
          :fill="`url(#${uuid}_paint6_linear_1484_21047)`"
        />
        <path
          d="M245.676 42.6718C245.526 42.6504 245.375 42.6289 245.225 42.6289H237.688C236.529 42.6289 235.584 43.5737 235.584 44.7332C235.584 45.8927 236.529 46.8375 237.688 46.8375H241.575L239.621 50.2086C238.805 51.5828 239.857 53.4079 241.446 53.365C242.176 53.365 242.885 52.9785 243.271 52.3129L247.05 45.7853C247.05 45.7853 247.115 45.6565 247.157 45.5706C247.694 44.3896 246.943 42.908 245.676 42.6504V42.6718Z"
          :fill="`url(#${uuid}_paint7_linear_1484_21047)`"
        />
      </g>
    </g>
    <defs>
      <filter
        :id="`${uuid}_filter0_d_1484_21047`"
        x="138.723"
        y="49.3716"
        width="82.5688"
        height="82.5688"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4.33028" />
        <feGaussianBlur stdDeviation="5.1422" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.890196 0 0 0 0 0.184314 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1484_21047"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1484_21047"
          result="shape"
        />
      </filter>
      <filter
        :id="`${uuid}_filter1_i_1484_21047`"
        x="156.402"
        y="62.7202"
        width="47.2109"
        height="49.3761"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.16514" />
        <feGaussianBlur stdDeviation="1.8945" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.691667 0 0 0 0 0.415 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1484_21047"
        />
      </filter>
      <filter
        :id="`${uuid}_filter2_d_1484_21047`"
        x="160.415"
        y="72.8829"
        width="39.7534"
        height="36.6904"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.62385" />
        <feGaussianBlur stdDeviation="2.70642" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.766667 0 0 0 0 0.414 0 0 0 0 0 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1484_21047"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1484_21047"
          result="shape"
        />
      </filter>
      <filter
        :id="`${uuid}_filter3_d_1484_21047`"
        x="209.928"
        y="24.4047"
        width="55.1194"
        height="55.1189"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2.89068" />
        <feGaussianBlur stdDeviation="3.43268" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.890196 0 0 0 0 0.184314 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1484_21047"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1484_21047"
          result="shape"
        />
      </filter>
      <filter
        :id="`${uuid}_filter4_i_1484_21047`"
        x="221.729"
        y="33.3154"
        width="31.5156"
        height="32.961"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.44534" />
        <feGaussianBlur stdDeviation="1.26467" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.691667 0 0 0 0 0.415 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_1484_21047"
        />
      </filter>
      <filter
        :id="`${uuid}_filter5_d_1484_21047`"
        x="224.408"
        y="40.0996"
        width="26.5372"
        height="24.4928"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.08401" />
        <feGaussianBlur stdDeviation="1.80668" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.766667 0 0 0 0 0.414 0 0 0 0 0 0 0 0 0.8 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1484_21047"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1484_21047"
          result="shape"
        />
      </filter>
      <linearGradient
        :id="`${uuid}_paint0_linear_1484_21047`"
        x1="180.292"
        y1="117.326"
        x2="180.292"
        y2="55.3257"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFAE01" />
        <stop offset="1" stop-color="#FFEE80" />
      </linearGradient>
      <radialGradient
        :id="`${uuid}_paint1_radial_1484_21047`"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(180.008 62.7202) rotate(90) scale(47.211)"
      >
        <stop stop-color="#FFE200" />
        <stop offset="1" stop-color="#FCCB01" />
      </radialGradient>
      <linearGradient
        :id="`${uuid}_paint2_linear_1484_21047`"
        x1="174.645"
        y1="76.6719"
        x2="174.645"
        y2="102.537"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`${uuid}_paint3_linear_1484_21047`"
        x1="185.956"
        y1="76.6719"
        x2="185.956"
        y2="92.7558"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`${uuid}_paint4_linear_1484_21047`"
        x1="237.677"
        y1="69.7676"
        x2="237.677"
        y2="28.3794"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFAE01" />
        <stop offset="1" stop-color="#FFEE80" />
      </linearGradient>
      <radialGradient
        :id="`${uuid}_paint5_radial_1484_21047`"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(237.486 33.3154) rotate(90) scale(31.5158)"
      >
        <stop stop-color="#FFE200" />
        <stop offset="1" stop-color="#FCCB01" />
      </radialGradient>
      <linearGradient
        :id="`${uuid}_paint6_linear_1484_21047`"
        x1="233.907"
        y1="42.6289"
        x2="233.907"
        y2="59.895"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        :id="`${uuid}_paint7_linear_1484_21047`"
        x1="241.458"
        y1="42.6289"
        x2="241.458"
        y2="53.3657"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.21875" stop-color="#FFF1C4" />
        <stop offset="1" stop-color="#FFEEB6" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
