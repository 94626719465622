<script setup lang="ts">
import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import AppModal from '@/components/app/AppModal/AppModal.vue';
import AppButton from '@/components/app/AppButton/AppButton.vue';
import FTXSlideComponent from '@/components/ftx/FTXSlide/FTXSlide.vue';
import AppBulletSlider from '@/components/app/AppBulletSlider/AppBulletSlider.vue';
import useFTXList from '@/composables/useFTXList/useFTXList';
import { FTXPage, FTXSlide, FTXView } from '@/shared/types/ftx';
import useProfileStore from '@/store/profile/useProfileStore';
import useSteps from '@/composables/useSteps/useSteps';
import { StepsButtonsLabel } from '@/shared/types/generic';
import usePostHogEvents from '@/composables/usePostHog/usePostHogEvents';
import { PostHogEvents } from '@/shared/types/posthog';
import ChallengesFTX from '@/assets/icons/ftx-challenges.vue';
import MeasureFTX from '@/assets/icons/ftx-measure.vue';
import PrizesFTX from '@/assets/icons/ftx-prizes.vue';

const { t } = useI18n();

const profileStore = useProfileStore();

const { postHogEvent } = usePostHogEvents();

const state = reactive<FTXView>({ show: true });

const slidesList: FTXSlide[] = [
  {
    key: FTXPage.WELCOME,
    title: t('ftx.welcome.title', [profileStore.userProfile?.user.username]),
    description: t('ftx.welcome.description'),
  },
  {
    key: FTXPage.CHALLENGES,
    title: t('ftx.challenges.title'),
    imageSlide: ChallengesFTX,
    imageDescription: 'onboarding challenges page',
    description: t('ftx.challenges.description'),
  },
  {
    key: FTXPage.PRIZES,
    title: t('ftx.rewards.title'),
    imageSlide: PrizesFTX,
    imageDescription: 'onboarding rewards page',
    description: t('ftx.rewards.description'),
  },
  {
    key: FTXPage.SURVEYS,
    title: t('ftx.measure.title'),
    imageSlide: MeasureFTX,
    imageDescription: 'onboarding measure page',
    description: t('ftx.measure.description'),
  },
];

const { slidesContent } = useFTXList(slidesList);

const buttonsLabel: StepsButtonsLabel = {
  last: t('labels.common.getStarted'),
  next: t('labels.common.next'),
};

const { stepIndex, isLastStep, nextStep, labelNextAndLastButton } = useSteps(
  slidesContent.length,
  buttonsLabel,
);

const updateFTXAndGoToHome = async () => {
  await profileStore.updateShowFtx(false);
  state.show = false;
};

const nextSlide = () => {
  if (isLastStep.value) {
    updateFTXAndGoToHome();
  } else {
    nextStep();
  }
};

const dismissFTX = () => {
  updateFTXAndGoToHome();

  postHogEvent(PostHogEvents.FTX_DISMISS_INITIAL_MANAGER);
};
</script>

<template>
  <AppModal
    v-model="state.show"
    width="800px"
    class="ftx-background"
    :show-footer="false"
    :show-header="false"
    persistent
  >
    <template #header></template>
    <template #body>
      <div class="ftx__container">
        <FTXSlideComponent :slide="slidesContent[stepIndex]" />
        <AppBulletSlider
          :total-bullets="slidesContent.length"
          :highlighted-index="stepIndex + 1"
        />
        <div class="ftx-slide__buttons">
          <AppButton
            type="vaibe"
            size="M"
            rounded
            :label="labelNextAndLastButton"
            @click="nextSlide"
          />
          <AppButton
            v-if="!isLastStep"
            type="empty"
            size="M"
            rounded
            :label="t('labels.common.dismiss')"
            @click="dismissFTX"
          />
        </div>
      </div>
    </template>
  </AppModal>
</template>

<style lang="scss">
.ftx-background {
  z-index: 6002;
}
.ftx__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 48px 152px;
  overflow: hidden;
}

.ftx-slide__buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 28px;
}
</style>
