<template>
  <svg
    width="361"
    height="151"
    viewBox="0 0 361 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="360"
      height="150"
      transform="translate(0.226562 0.196289)"
      fill="white"
    />
    <path
      d="M180.226 98.9546C178.39 98.2588 173.81 96.3439 168.857 92.8534C160.449 86.9304 154.971 79.4893 152.57 70.7486C150.956 64.8602 151.603 59.8055 154.447 56.1367C156.847 53.043 160.618 51.2661 164.79 51.2661C170.028 51.2661 174.807 54.0321 177.901 58.851L180.226 62.4795L182.551 58.851C185.639 54.0321 190.417 51.2661 195.662 51.2661C199.834 51.2661 203.604 53.043 206.005 56.1367C208.849 59.8055 209.496 64.8544 207.881 70.7486C205.481 79.4951 200.003 86.9304 191.595 92.8534C186.642 96.3439 182.067 98.2588 180.226 98.9546Z"
      fill="#DB0505"
    />
    <path
      d="M180.227 96.0334V101.841C180.227 101.841 169.482 98.4198 160.496 89.3743C156.027 84.8775 151.989 78.9947 149.92 71.4559C148.877 67.6434 148.731 64.2506 149.273 61.3179L154.471 64.1988C154.401 65.9872 154.658 67.9424 155.235 70.0413C156.948 76.3035 160.392 81.8527 165.484 86.6141C166.959 87.9942 168.573 89.3053 170.327 90.5474C174.353 93.4054 178.135 95.165 180.233 96.0276L180.227 96.0334Z"
      fill="#FF5A62"
    />
    <path
      d="M211.18 61.3178L205.982 64.1987C205.889 61.6915 205.167 59.5236 203.821 57.7927C201.944 55.3718 198.972 53.9859 195.663 53.9859C191.386 53.9859 187.452 56.2919 184.882 60.3057L180.232 67.5628V57.4017C184.113 51.3465 190.022 48.5518 195.668 48.5518C202.9 48.5518 209.689 53.1406 211.186 61.3178H211.18Z"
      fill="#FF5A62"
    />
    <path
      d="M149.271 61.3179L154.469 64.1988C154.563 61.6916 155.291 59.5237 156.637 57.7928C158.513 55.3719 161.485 53.986 164.789 53.986V48.5576C157.563 48.5576 150.775 53.1465 149.271 61.3236V61.3179Z"
      fill="#AF0011"
    />
    <path
      d="M180.225 57.4017C176.344 51.3465 170.43 48.5518 164.789 48.5518V53.9802C169.066 53.9802 172.999 56.2861 175.569 60.2999L180.219 67.557V57.396L180.225 57.4017Z"
      fill="#AF0011"
    />
    <path
      d="M211.178 61.3179L205.981 64.1988C206.05 65.9872 205.8 67.9424 205.223 70.0413C203.51 76.3035 200.066 81.8527 194.973 86.6199C193.499 87.9942 191.885 89.3111 190.131 90.5532C186.098 93.4111 182.317 95.1708 180.225 96.0334V101.841C180.225 101.841 190.97 98.4198 199.955 89.3743C204.425 84.8775 208.463 78.9947 210.531 71.4559C211.575 67.6434 211.72 64.2506 211.178 61.3179Z"
      fill="#AF0011"
    />
  </svg>
</template>
