import { random } from 'lodash';

const generatedNumbers: Set<number> = new Set([0]);

export default (max = 10, min = 0): number => {
  let randomNumber = 0;

  while (generatedNumbers.has(randomNumber)) {
    randomNumber = Math.floor(random(min, max));
  }

  return randomNumber;
};
